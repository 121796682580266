.cellDay {
    height: 3em;
    padding-top: 0.7em;
}

.cellMonth {
    vertical-align: top;
    height: 156px !important;
    width: 156px !important;
    line-height: normal !important;
    font-size: 0.9em !important;
}

.linkCell {
    width: 1.5em;
    height: 1.5em;
    text-align: center;
}

.cellToolTipMonth {
    height: 7.35em;
    width: 2.35em;
}

.cellList {
    list-style-type: square;
    color: #007bff !important;
    text-align: left;
}

.blackColor {
    color: black;
}

.more {
    font-size: 0.9em;
    font-weight: 700;
    padding-left: 0.1rem !important;
}

.squareSpace {
    padding-left: 1.2rem !important;
}

.squareSpan {
    margin-left: -6px;
    color: black;
}

.cellHeight {
    height: 60px !important;
    overflow: visible !important;
    padding: 0px !important;
    width: 85% !important;
    border-radius: 0.75em;
    color: black;
}

.cellHour {
    height: 60px !important;
    padding-top: 16px;
    border-radius: 0.75em;
    background-color: #504c4c;
    color: white;
}