#Calendar.tabcontent {
    flex-direction: column;
}

.react-datetime-picker {
    padding: 0.375rem 0.75rem;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: #212529;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid #ced4da;
    border-radius: 0.25rem;
}

.react-datetime-picker__wrapper {
    border: none !important;
}

.text-view-title {
    display: flex;
    font-size: 21px;
    height: 45px;
    padding: 18px;
    padding-top: 16px;
    color: #fff;
    background-color: #EB0909;
    border-radius: 8px;
    justify-content: center;
    align-items: center;
}