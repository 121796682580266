.cellWeek {
    height: 60px !important;
    overflow: visible !important;
    padding: 0px !important;
    width: 150px !important;
}

.hourCell {
    width: 30px !important;
    background-color: #504c4c !important;
    color: white !important;
}

.border-week {
    color: rgb(239, 83, 80);
}

.blockEvent {
    position: absolute !important;
    background-color: #007bff !important;
    border: 1px solid black !important;
    border-radius: 1% !important;
    color: white;
    /* height: 60px !important;
    width : 150px !important; */
}

.loadingWeek {
    margin-left: 50%;
    margin-top: 25%;
    display: block;
}

.react-draggable,
.cursor {
    cursor: move;
}

.no-cursor {
    cursor: auto;
}

.cursor-y {
    cursor: ns-resize;
}

.cursor-x {
    cursor: ew-resize;
}

.cursorDefault {
    cursor: default !important;
}