#Chat {
    height: calc(100vh - 70px);
    display: flex;
}

#Chat .chatMenu {
    flex: 3.5;
}

#Chat .chatMenu .chatMenuInput {
    width: 90%;
    padding: 10px 0;
    border: none;
    border-bottom: 1px solid gray;
}

#Chat .chatBox {
    flex: 5.5;
}

#Chat .chatBox .chatBoxWrapper {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    position: relative;
}

#Chat .chatBox .chatBoxTop {
    max-height: 80vh;
    padding-right: 10px;
}

#Chat .chatBox .chatBoxBottom {
    height: 10vh;
    margin-top: 5px;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

#Chat .chatBox .chatBoxBottom .chatMessageInput {
    width: 80%;
    height: 90px;
    padding: 10px;
    border: 2px solid gray;
    border-radius: 10px;
}

#Chat .chatBox .chatBoxBottom .chatSubmitButton {
    width: 70px;
    height: 40px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    background-color: teal;
    color: white;
}

#Chat .chatOnline {
    flex: 3;
}

#Chat .chatMenuWrapper,
#Chat .chatBoxWrapper .chatBoxTop,
#Chat .chatOnlineWrapper {
    padding: 10px;
    overflow-y: scroll;
}

#Chat .chatMenuWrapper,
#Chat .chatOnlineWrapper {
    height: 90vh;
}

@media screen and (max-width: 960px) {
    #Chat {
        flex-direction: column;
    }

    #Chat .chatMenuWrapper,
    #Chat .chatOnlineWrapper {
        height: 100%;
        max-height: 50vh;
    }

    #Chat .chatMenu .chatMenuInput {
        width: 90%;
        padding: 10px 0;
        border: none;
        border-bottom: 1px solid gray;
    }

    #Chat .conversation {
        margin-top: 0px;
    }
}