.button {
    margin-left: 36%;
    margin-top: -13%;
}

.view-cal button {
    font-size: 21px;
    color: #fff !important;
    background-color: #EB0909;
    border-radius: 8px;
    border: none !important;
}

.view-cal button:hover {
    font-size: 21px;
    color: rgb(25, 25, 25);
    border-radius: 8px;
    border: none !important;
}