#To_Do_List .container .table-wrap {
    margin: 20px auto;
    overflow-x: auto
}

#To_Do_List .container .table-wrap::-webkit-scrollbar {
    height: 5px
}

#To_Do_List .container .table-wrap::-webkit-scrollbar-thumb {
    border-radius: 5px;
    background-image: linear-gradient(to right, #5D7ECD, #0C91E6)
}

#To_Do_List img {
    height: 30px;
    width: 30px;
    object-fit: cover;
}

#To_Do_List .w100 {
    max-width: 100px;
    min-width: 100px;
}

#To_Do_List .w350 {
    max-width: 550px;
    min-width: 550px;
}

#To_Do_List .btn.btn-primary.h-1 {
    background-color: #FB0778;
    color: white;
    font-size: 14px;
    border: none;
    padding: 2px 10px;
}

#To_Do_List .btn.btn-primary.h-1:hover {
    background-color: #ee1a7d;
}

#To_Do_List h3.title {
    margin-bottom: 10px;
    font-weight: bold;
    font-size: 29px;
}

#To_Do_List .btn.btn-primary.h-2 {
    background-color: #f8d303;
    color: white;
    font-size: 14px;
    border: none;
    padding: 2px 10px;
}

#To_Do_List .btn.btn-primary.h-2:hover {
    background-color: #c5b140;
}

#To_Do_List .btn.btn-primary.h-3 {
    background-color: #6f00ff;
    color: white;
    font-size: 14px;
    border: none;
    padding: 2px 10px;
}

#To_Do_List .btn.btn-primary.h-3:hover {
    background-color: #7638c9;
}

#To_Do_List .bg-pink {
    height: 10px;
    width: 10px;
    background-color: #ee1a7d;
}

#To_Do_List .bg-green {
    height: 10px;
    width: 10px;
    background-color: #1f8713;
}

#To_Do_List .bg-gray {
    height: 10px;
    width: 10px;
    background-color: #565e64;
}

#To_Do_List .bg-yellow {
    height: 10px;
    width: 10px;
    background-color: #f8d303;
}

#To_Do_List .bg-violet {
    height: 10px;
    width: 10px;
    background-color: #6f00ff;
}

#To_Do_List .btn.btn-secondary.gray {
    background-color: transparent;
    font-size: 12px;
    border: none;
    color: #fff;
    background-color: #565e64;
    width: 100%;
    padding: 5px 15px;
}

#To_Do_List .btn.btn-secondary.green {
    background-color: transparent;
    font-size: 12px;
    border: none;
    color: #1f8713;
    background-color: #acd89c;
    width: 100%;
    padding: 5px 15px;
}

#To_Do_List .btn.btn-secondary.pink {
    background-color: transparent;
    font-size: 12px;
    border: none;
    background-color: #f5cade;
    color: #ee1a7d;
    width: 100%;
    padding: 5px 15px;
}

#To_Do_List .btn.btn-secondary.violet {
    background-color: transparent;
    font-size: 12px;
    border: none;
    color: #7638c9;
    background-color: #d8c6f0;
    width: 100%;
    padding: 5px 15px;
}

#To_Do_List .btn.btn-secondary.yellow {
    background-color: transparent;
    font-size: 12px;
    border: none;
    background-color: #f7ecb1;
    color: #f88e03;
    width: 100%;
    padding: 5px 15px;
}

@media(min-width: 992px) {
    #To_Do_List .container .table-wrap {
        overflow: hidden;
    }
}