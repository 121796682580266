.todo-container .header {
    background-color: gray;
    padding: 1% 3% 2%;
    color: white;
    text-align: center;
    margin-top: 10px;
    font-size: 25px;
}

.todo-container .header:after {
    content: "";
    display: table;
    clear: both;
}

.todo-container .header input {
    font-size: 22px;
    border: none;
    width: 75%;
    padding: 10px;
    float: left;
}

.todo-container .header .addBtn {
    font-size: 22px;
    padding: 10px;
    width: 15%;
    background-color: #337ab7;
    color: #FFF;
    float: left;
    text-align: center;
    cursor: pointer;
    transition: 0.3s;
}

.todo-container .addBtn:hover {
    background-color: #0c145f;
}

.todo-container #myToDoList {
    height: 320px;
    overflow: scroll;
}

.todo-container #myToDoList .item {
    display: flex;
}

.todo-container #myToDoList .item .task {
    font-size: 22px;
    width: 96%;
    cursor: pointer;
    position: relative;
    padding: 20px;
    background: #eee;
    transition: 0.2s;

    /* make the list items unselectable */
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

.todo-container #myToDoList .item .task:hover {
    background: #ddd;
}

.todo-container #myToDoList .item.checked .task {
    background: #5cb85c;
    color: #fff;
    text-decoration: line-through;
}

.todo-container #myToDoList .item.checked .task::before {
    content: '';
    position: absolute;
    border-color: #fff;
    border-style: solid;
    border-width: 0 2px 2px 0;
    top: 20px;
    left: 16px;
    transform: rotate(45deg);
    height: 15px;
    width: 7px;
}

.todo-container #myToDoList .item .close {
    width: 4%;
    padding: 12px 16px 12px 16px;
    background-color: #f44336;
}

.todo-container #myToDoList .item .close:hover {
    background-color: #f44336;
    color: white;
}

@media screen and (max-width: 1024px) {
    .todo-container .header input, .todo-container .header .addBtn, .todo-container #myToDoList .item .task {
        font-size: 21px;
    }
}

@media screen and (max-width: 768px) {
    .todo-container .header input, .todo-container .header .addBtn, .todo-container #myToDoList .item .task {
        font-size: 18px;
    }

    .todo-container .header .addBtn {
        width: 25%;
    }
}

@media screen and (max-width: 480px) {
    .todo-container .header input, .todo-container .header .addBtn, .todo-container #myToDoList .item .task {
        font-size: 14px;
    }
}