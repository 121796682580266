.design3 {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 50px 0;
    height: 100vh;
    min-height: max-content;
    background: #252839;
}

.design3 ul {
    display: flex;
    flex-direction: column;
    gap: 30px;
}

.design3 li span {
    font-size: 70px;
    font-weight: 600;
    text-decoration: none;
    line-height: 1em;
    letter-spacing: 2px;
    color: #252839;
    filter: drop-shadow(-3px -3px 2px rgba(255, 255, 255, 0.15)) drop-shadow(5px 5px 5px rgba(0, 0, 0, 0.2)) drop-shadow(15px 15px 15px rgba(0, 0, 0, 0.2));
}

.design3 .liRed span:hover::before {
    width: 100%;
}

.design3 .liBlue span:hover::before {
    width: 100%;
}

.design3 .liGreen span:hover::before {
    width: 100%;
}

.design3 .liYellow span:hover::before {
    width: 100%;
}

.design3 .liWhite span:hover::before {
    width: 100%;
}

.design3 .liRed span::before {
    color: red;
    content: attr(data-text);
    position: absolute;
    width: 0;
    overflow: hidden;
    -webkit-text-stroke: 0px red;
    filter: drop-shadow(0 0 20px red);
    transition: 1s;
    border-right: 8px solid red;
}

.design3 .liBlue span::before {
    color: #00ade1;
    content: attr(data-text);
    position: absolute;
    width: 0;
    overflow: hidden;
    -webkit-text-stroke: 0px #00ade1;
    filter: drop-shadow(0 0 20px #00ade1);
    transition: 1s;
    border-right: 8px solid #00ade1;
}

.design3 .liGreen span::before {
    color: #00dc82;
    content: attr(data-text);
    position: absolute;
    width: 0;
    overflow: hidden;
    -webkit-text-stroke: 0px #00dc82;
    filter: drop-shadow(0 0 20px #00dc82);
    transition: 1s;
    border-right: 8px solid #00dc82;
}

.design3 .liYellow span::before {
    color: #ffdd1c;
    content: attr(data-text);
    position: absolute;
    width: 0;
    overflow: hidden;
    -webkit-text-stroke: 0px #ffdd1c;
    filter: drop-shadow(0 0 20px #ffdd1c);
    transition: 1s;
    border-right: 8px solid #ffdd1c;
}

.design3 .liWhite span::before {
    color: white;
    content: attr(data-text);
    position: absolute;
    width: 0;
    overflow: hidden;
    -webkit-text-stroke: 0px white;
    filter: drop-shadow(0 0 15px white);
    transition: 1s;
    border-right: 8px solid white;
}

@media screen and (max-width: 872px) {
    .design3 li span {
        font-size: 60px;
    }
}

@media screen and (max-width: 586px) {
    .design3 {
        height: 80%;
        min-height: max-content;
    }

    .design3 li span {
        font-size: 50px;
    }

    .demo4 ul li {
        position: relative;
        list-style: none;
        width: 38px;
        height: 38px;
        margin: 0 10px;
    }
}

@media screen and (max-width: 375px) {
    .design3 li span {
        font-size: 40px;
    }
}