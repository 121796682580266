#Offres .offres-title {
    background: var(--redGradient2);
    color: white;
    text-align: center;
    margin-bottom: 0;
    padding: 5px;
    font-weight: 600;
    font-size: xx-large;
}

#Offres .card {
    display: grid;
    grid-template-columns: 1fr;
    grid-column-gap: 20px;
    grid-auto-flow: row dense;
    counter-reset: card;
}

#Offres .project {
    counter-increment: card;
    cursor: pointer;
    margin-bottom: 37px;
    max-width: 240px;
    min-width: 200px;
    border-radius: 0 30px 0 30px;
    box-shadow: 5px 5px 15px rgb(0 0 0 / 90%);
    transition: 0.5s ease;
}

#Offres .project:hover {
    transform: scale(1.15);
    box-shadow: 5px 5px 15px rgba(0, 0, 0, 0.6);
}

#Offres .project__image {
    width: 100%;
    height: 150px;
    grid-area: image;
    border-top-right-radius: 30px;
    background-size: cover;
    object-fit: cover;
}

#Offres h2 {
    padding: 0;
    color: #000;
    margin: 0 0 5px;
}

#Offres .project__name {
    padding: 10px;
    text-align: center;
    font-size: 20px;
    font-weight: 500;
}

#Offres .project__description {
    padding: 0 10px 20px 10px;
}

#Offres .project__body {
    overflow: hidden;
}

#Offres .project__body p {
    text-indent: 10%;
}

#Offres .project__body p:last-child {
    text-indent: 0;
}

#Offres .project .project__body {
    display: none;
}

#Offres .card-flex {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
    gap: 15px;
}

@media screen and (min-width: 640px) {
    #Offres .card {
        grid-template-columns: repeat(4, 1fr);
    }

    #Offres .project__body {
        grid-column: span 4;
    }

    #Offres .card-flex {
        margin-left: -10px;
        margin-right: -10px;
    }

    #Offres .card-flex .project {
        width: 22%;
    }

    #Offres .card-flex .project__body {
        padding: 0 10px;
    }
}