#Demo .container .demo-title {
    background: var(--redGradient2);
    color: white;
    text-align: center;
    margin-bottom: 0;
    padding: 5px;
    font-weight: 600;
    font-size: xx-large;
}

#Demo .container .demo-border {
    padding: 1rem !important;
    border: 2px solid #7C7F83;
    border-radius: 0 0 10px 10px;
    border-top: none;
}