footer {
    box-shadow: 0px -5px 5px rgb(0, 0, 0, 0.5);
    background-color: black;
}

.footer {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
    align-items: center;
    font-size: 14px;
}

.footer .text-first {
    text-align: left;
}

.footer .copyright {
    display: flex;
    align-items: center;
}

.footer .copyright .footer-facebook {
    font-size: 30px;
    color: white;
}

.footer div a {
    color: #6c757d;
}

@media screen and (max-width: 872px) {
    .footer .text-first {
        text-align: center;
    }
}

@media screen and (max-width: 762px) {
    .footer .footer-facebook {
        font-size: 25px;
    }
}

@media screen and (max-width: 494px) {
    .footer {
        flex-direction: column;
    }

    .footer div {
        text-align: center !important;
    }

    .footer div.thrid {
        display: flex;
        flex-direction: column;
    }
}

@media screen and (max-width: 320px) {
    .footer .copyright {
        display: block;
        align-items: center;
    }
}