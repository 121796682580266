#Tache.tabcontent {
    flex-direction: column;
}

#Tache .tableSelect {
    text-align: center;
    width: -webkit-fill-available;
    border-collapse: separate;
    border-spacing: 12px;
    margin-top: 2%;
}

#Tache .tableSelect .tableIcon1 {
    color: brown;
    font-size: larger;
    margin-left: 72%;
    margin-top: -14%;
}

#Tache .tableSelect .tableIcon2 {
    color: brown;
    font-size: larger;
    margin-left: 60%;
    margin-top: -8%;
}

.zone-selection {
    width: 53%;
    height: 30px;
    border-radius: 3px;
    border-bottom: 2px solid rgb(230, 16, 16);
    overflow: hidden;
}

.zone-selection select {
    width: 68%;
    font-size: 14px;
    text-align: center;
    padding: 5px 10px;
    appearance: none;
}

/* Positionne la flèche à droite de la zone de sélection */
.zone-selection {
    position: relative;
}

/* Cette classe affichera la flèche personnalisée */
.select-arrow {
    position: absolute;
    top: 52%;
    right: 4px;
    transform: translateY(-50%);
    width: 10px;
    height: 0;
    border-left: 4px solid transparent;
    border-right: 4px solid transparent;
    border-top: 4px solid black;
    pointer-events: none;
}


/* Cette classe fera pivoter la flèche lorsqu'elle est active */
.zone-selection.active .select-arrow {
    transform: translateY(-50%) rotate(180deg);
}

#Tache .tacheTable {
    width: 96%;
    margin-top: 1%;
    margin-left: 3%;
    max-height: 62vh;
    height: 62vh;
    overflow-x: auto;
    /* border: 1px solid #ccc; */
}

#Tache .tacheTable .table-group-divider {
    background-color: white;
}

#Tache .tacheTable .table {
    text-align: center;
    background-color: whitesmoke;
}

#Tache .tacheTable .table i {
    font-size: 26px;
    margin-left: 24vh;
    margin-top: -27px;
}

#Tache .modal {
    top: 14%;
    left: 10%;
}

#Tache .modal-footer {
    display: block;
}

#Tache div[aria-label*="Edit text"] {
    min-height: 200px;
}