#background_lanzo {
    height: 100vh;
}

#Accueil .accueil-container {
    width: 100%;
    height: 100vh;
    padding-top: 32vh;
    text-align: center;
    overflow: hidden;
    color: white;
    font-family: "Fredoka";
    background: var(--redGradient);
}

#Accueil .accueil-container strong span span {
    font-size: 6.5vw;
    font-weight: 600;
}

#Accueil .accueil-container span span span {
    text-align: center;
    font-size: 3.5vw;
}

#Accueil .accueil-container span {
    animation: titleReveal 0.7s both;
}

#Accueil .accueil-container div.animeLeft {
    background: url("./../img/Logo_Transparent_Officiel.png") center center / contain no-repeat;
    height: 10vw;
    margin-left: -30vw;
    transition: transform 3s;
}

#Accueil .accueil-container div.animeLeft.right {
    transform: translateX(31vw);
}

@keyframes titleReveal {
    from {
        opacity: 0;
        transform: translateY(100%);
    }

    to {
        opacity: 1;
        transform: translateY(0);
    }
}

@media screen and (max-width: 960px) {
    #background_lanzo {
        top: 80px;
    }
}