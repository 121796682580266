.galery-container .galery-menu {
    margin-top: 10px;
    list-style: none;
    display: flex;
    flex-direction: row;
    justify-content: center;
}

.galery-container .galery-menu li {
    flex-basis: 25%;
    text-align: center;
    cursor: pointer;
    padding: 0.5%;
    border-radius: 5px;
}

.galery-container .galery-menu .menu-active {
    background-color: #EB5956;
    color: white;
}

.galery-container .imgs-galery {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
}

.galery-container .imgs-galery div img {
    width: 100%;
    height: 198px;
    padding: 0.5%;
    object-fit: cover;
}

@media screen and (max-width: 1024px) {
    .galery-container .imgs-galery div img {
        height: 120px;
    }
}

@media screen and (max-width: 768px) {
    .galery-container .imgs-galery div img {
        height: 100px;
    }
}

@media screen and (max-width: 480px) {
    .galery-container .imgs-galery div img {
        height: 80px;
    }
}

@media screen and (max-width: 355px) {
    .galery-container .imgs-galery div img {
        height: 65px;
    }
}