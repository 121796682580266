#Contact .contact-title {
    background: var(--redGradient2);
    color: white;
    text-align: center;
    margin-bottom: 0;
    padding: 5px;
    font-weight: 600;
    font-size: xx-large;
}

#Contact .contact-form {
    padding: 1rem;
    border: 2px solid #7C7F83;
    border-radius: 0 0 10px 10px;
    border-top: none;
}

#Contact form .form-control {
    border: none;
    border-bottom: 0.3px solid #7C7F83;
    transition: all 0.3s ease-in;
}

#Contact form .form-control:focus {
    box-shadow: none;
    border-color: rgb(0, 0, 0);
    border-bottom: 2px solid black;
}

#Contact form textarea.form-control {
    border: 0.3px solid #7C7F83;
}

#Contact form textarea.form-control:focus {
    border: 1.8px solid black;
}

#Contact form .error-contact {
    padding: 0.5rem;
}