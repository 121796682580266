#Project .modalDescription {
    display: none;
    position: fixed;
    z-index: 1;
    padding-top: 100px;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    overflow: auto;
    background-color: rgb(0, 0, 0);
    background-color: rgba(0, 0, 0, 0.4);
}

#Project .modalContent {
    position: relative;
    background-color: #fefefe;
    margin: auto;
    padding: 0;
    border: 1px solid #888;
    width: 60%;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
    -webkit-animation-name: animatetop;
    -webkit-animation-duration: 0.4s;
    animation-name: animatetop;
    animation-duration: 0.4s
}

#Project .page-link {
    color: #000 !important;
}

@-webkit-keyframes animatetop {
    from {
        top: -300px;
        opacity: 0
    }

    to {
        top: 0;
        opacity: 1
    }
}

@keyframes animatetop {
    from {
        top: -300px;
        opacity: 0
    }

    to {
        top: 0;
        opacity: 1
    }
}


#Project .close {
    color: white;
    float: right;
    font-size: 28px;
    font-weight: bold;
}

#Project .close:hover,
#Project .close:focus {
    color: #000;
    text-decoration: none;
    cursor: pointer;
}

#Project .modalHeader {
    padding: 2px 16px;
    background: var(--redGradient2);
    color: white;
}

#Project .modalBody {
    padding: 2px 16px;
}