.design2 {
    margin: 0;
    padding: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100vw;
    height: 100vh;
    max-height: 60vw;
    background: #222;
}

.design2 ul {
    position: relative;
    padding-left: 0;
    display: flex;
    transform-style: preserve-3d;
    transform: rotate(-25deg) skew(25deg);
}

.design2 ul li {
    position: relative;
    list-style: none;
    width: 60px;
    height: 60px;
    margin: 0 10px;
}

.design2 ul li span.parent span {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex !important;
    justify-content: center;
    align-items: center;
    background: #333;
    color: rgba(255, 255, 255, 0.2);
    font-size: 35px !important;
    font-weight: 600;
    transition: 0.2s;
}

.design2 ul li:hover span.parent span {
    z-index: 1000;
    transition: 0.5s;
    color: #fff;
    box-shadow: -1px 1px 1px rgba(0, 0, 0, 0.05);
}

.design2 ul li:hover span.parent span:nth-child(5) {
    transform: translate(40px, -40px);
    opacity: 1;
}

.design2 ul li:hover span.parent span:nth-child(4) {
    transform: translate(30px, -30px);
    opacity: 0.8;
}

.design2 ul li:hover span.parent span:nth-child(3) {
    transform: translate(20px, -20px);
    opacity: 0.6;
}

.design2 ul li:hover span.parent span:nth-child(2) {
    transform: translate(10px, -10px);
    opacity: 0.4;
}

.design2 ul li:hover span.parent span:nth-child(1) {
    transform: translate(0px, -0px);
    opacity: 0.2;
}

.design2 ul li:nth-child(1):hover span.parent span {
    background: red;
}

.design2 ul li:nth-child(2):hover span.parent span {
    background: #00ade1;
}

.design2 ul li:nth-child(3):hover span.parent span {
    background: #00dc82;
}

.design2 ul li:nth-child(4):hover span.parent span {
    background: #ffdd1c;
    color: rgb(69, 69, 70);
}

.design2 ul li:nth-child(5):hover span.parent span {
    background: white;
    color: black;
}

/* mis en petit cube */
.design2 ul li:before {
    content: '';
    position: absolute;
    bottom: -10px;
    left: 0;
    width: 100%;
    height: 10px;
    background: #2a2a2a;
    transform-origin: top;
    transform: skew(-41deg);
}

.design2 ul li:after {
    content: '';
    position: absolute;
    top: 0;
    left: -9px;
    width: 9px;
    height: 100%;
    background: #2a2a2a;
    transform-origin: right;
    transform: skewY(-49deg);
}