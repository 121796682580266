#Dashboard.tabcontent {
    flex-direction: column;
}

#Dashboard .dashSelect {
    margin-left: 23px;
    margin-top: 3%;
    width: 50%;
}

#Dashboard .dashSelect label {
    margin-bottom: 10px;
    font-weight: bold;
    font-size: 21px;
}

#Dashboard .form-select {
    width: 86%;
    padding: 7px 40px 7px 12px;
    border: 2px solid #e8eaed;
    border-radius: 7px;
    box-shadow: 0 1px 4px -2px #9098a9;
    cursor: pointer;
    font-size: 17px;
    transition: all 150ms ease;
}

#Dashboard .dashContenu {
    width: -webkit-fill-available;
}

#Dashboard .dashTable1 {
    width: 103.5%;
    margin: 3% auto;
    margin-right: 44.5%;
}

#Dashboard .dashTable1 th {
    color: #E61010;
    font-size: 17px;
    padding: 6px;
    text-align: center;
}

#Dashboard .dashTable1 td {
    font-size: 16px;
    padding: 6px;
    text-align: center;
}

#Dashboard .dashTable2 p {
    margin-left: 0px;
    font-size: 18px;
}

#Dashboard .dashTable2 {
    width: 41%;
    margin-left: 30px;
}

#Dashboard .dashTable2 .dashTable {
    max-height: 44vh;
    height: 44vh;
    overflow-x: auto;
}

#Dashboard .dashTable2 th {
    font-size: 17px;
    padding: 14px;
    text-align: center;
}

#Dashboard .dashTable2 td {
    font-size: 16px;
    padding: 8px;
    text-align: center;
}

#Dashboard .dashChart {
    margin-top: 32px;
    display: flex;
}

#Dashboard p {
    margin-left: 75px;
    font-weight: bold;
    font-size: 18px;
}

#Dashboard .dashExport {
    display: flex;
    flex-direction: column;
}

/* #Dashboard .buttonPdf {
    margin-left: 31% !important;
    margin-top: -23% !important;
}

#Dashboard .buttonPdf .button {
    display: inline-block;
    transition: all 0.2s ease-in;
    position: relative;
    overflow: hidden;
    z-index: 1;
    color: #090909;
    padding: 0.7em 1.7em;
    cursor: pointer;
    font-size: 18px;
    border-radius: 0.5em;
    background: #e8e8e8;
    border: 1px solid #e8e8e8;
    box-shadow: 6px 6px 12px #c5c5c5, -6px -6px 12px #ffffff;
}

#Dashboard .buttonPdf .button:active {
    color: #666;
    box-shadow: inset 4px 4px 12px #c5c5c5, inset -4px -4px 12px #ffffff;
}

#Dashboard .buttonPdf .button:before {
    content: "";
    position: absolute;
    left: 50%;
    transform: translateX(-50%) scaleY(1) scaleX(1.25);
    top: 100%;
    width: 140%;
    height: 180%;
    background-color: rgba(0, 0, 0, 0.05);
    border-radius: 50%;
    display: block;
    transition: all 0.5s 0.1s cubic-bezier(0.55, 0, 0.1, 1);
    z-index: -1;
}

#Dashboard .buttonPdf .button:after {
    content: "";
    position: absolute;
    left: 55%;
    transform: translateX(-50%) scaleY(1) scaleX(1.45);
    top: 180%;
    width: 160%;
    height: 190%;
    background-color: #009087;
    border-radius: 50%;
    display: block;
    transition: all 0.5s 0.1s cubic-bezier(0.55, 0, 0.1, 1);
    z-index: -1;
}

#Dashboard .buttonPdf .button:hover {
    color: #ffffff;
    border: 1px solid rgb(230, 16, 16);
}

#Dashboard .buttonPdf .button:hover:before {
    top: -35%;
    background-color: #009087;
    transform: translateX(-50%) scaleY(1.3) scaleX(0.8);
}

#Dashboard .buttonPdf .button:hover:after {
    top: -45%;
    background-color: rgb(230, 16, 16);
    transform: translateX(-50%) scaleY(1.3) scaleX(0.8);
} */

#Dashboard .exportPDF {
    height: 51px;
    width: 13%;
    margin-left: 57%;
    margin-top: -4%;
    border: 1px solid #e8e8e8;
    background: #e8e8e8;
    border-radius: 6px;
    overflow: hidden;
    box-shadow: 4px 6px 11px #c5c5c5, -6px -6px 12px #ffff;
}

#Dashboard .button-content {
    transform: translateY(-45px);
    transition: all 250ms ease-in-out;
}

#Dashboard .svg-container,
.text-container {
    height: 45px;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

#Dashboard .text-container .text {
    font-size: 19px;
    color: black;
    font-weight: 400;
    opacity: 1;
    transition: opacity ease-in-out 250ms;
}

#Dashboard .download-icon {
    height: 25px;
    width: 25px;
    fill: #fff;
    opacity: 0;
    transition: opacity ease-in-out 250ms;
}

/* hover state for the button */
#Dashboard .exportPDF:hover .button-content {
    transform: translateY(0px);
}

#Dashboard .exportPDF:hover .text {
    opacity: 0;
}

#Dashboard .exportPDF:hover .download-icon {
    opacity: 1;
}

#Dashboard .exportPDF:focus .download-icon {
    -webkit-animation: heartbeat 1.5s ease-in-out infinite both;
    animation: heartbeat 1.5s ease-in-out infinite both;
}

@-webkit-keyframes heartbeat {
    from {
        -webkit-transform: scale(1);
        transform: scale(1);
        -webkit-transform-origin: center center;
        transform-origin: center center;
        -webkit-animation-timing-function: ease-out;
        animation-timing-function: ease-out;
    }

    10% {
        -webkit-transform: scale(0.91);
        transform: scale(0.91);
        -webkit-animation-timing-function: ease-in;
        animation-timing-function: ease-in;
    }

    17% {
        -webkit-transform: scale(0.98);
        transform: scale(0.98);
        -webkit-animation-timing-function: ease-out;
        animation-timing-function: ease-out;
    }

    33% {
        -webkit-transform: scale(0.87);
        transform: scale(0.87);
        -webkit-animation-timing-function: ease-in;
        animation-timing-function: ease-in;
    }

    45% {
        -webkit-transform: scale(1);
        transform: scale(1);
        -webkit-animation-timing-function: ease-out;
        animation-timing-function: ease-out;
    }
}

@keyframes heartbeat {
    from {
        -webkit-transform: scale(1);
        transform: scale(1);
        -webkit-transform-origin: center center;
        transform-origin: center center;
        -webkit-animation-timing-function: ease-out;
        animation-timing-function: ease-out;
    }

    10% {
        -webkit-transform: scale(0.91);
        transform: scale(0.91);
        -webkit-animation-timing-function: ease-in;
        animation-timing-function: ease-in;
    }

    17% {
        -webkit-transform: scale(0.98);
        transform: scale(0.98);
        -webkit-animation-timing-function: ease-out;
        animation-timing-function: ease-out;
    }

    33% {
        -webkit-transform: scale(0.87);
        transform: scale(0.87);
        -webkit-animation-timing-function: ease-in;
        animation-timing-function: ease-in;
    }

    45% {
        -webkit-transform: scale(1);
        transform: scale(1);
        -webkit-animation-timing-function: ease-out;
        animation-timing-function: ease-out;
    }
}


#Dashboard .exportCSV {
    height: 51px;
    width: 13%;
    margin-left: 24%;
    margin-top: 24%;
    border: 1px solid #e8e8e8;
    background: #e8e8e8;
    border-radius: 6px;
    overflow: hidden;
    box-shadow: 4px 6px 11px #c5c5c5, -6px -6px 12px #ffff;
}

#Dashboard .button-content {
    transform: translateY(-45px);
    transition: all 250ms ease-in-out;
}

#Dashboard .svg-container,
.text-container {
    height: 45px;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

#Dashboard .text-container .text {
    font-size: 19px;
    color: black;
    font-weight: 400;
    opacity: 1;
    transition: opacity ease-in-out 250ms;
}

#Dashboard .download-icon {
    height: 25px;
    width: 25px;
    fill: #fff;
    opacity: 0;
    transition: opacity ease-in-out 250ms;
}

/* hover state for the button */
#Dashboard .exportCSV:hover .button-content {
    transform: translateY(0px);
}

#Dashboard .exportCSV:hover .text {
    opacity: 0;
}

#Dashboard .exportCSV:hover .download-icon {
    opacity: 1;
}

#Dashboard .exportCSV:focus .download-icon {
    -webkit-animation: heartbeat 1.5s ease-in-out infinite both;
    animation: heartbeat 1.5s ease-in-out infinite both;
}

@-webkit-keyframes heartbeat {
    from {
        -webkit-transform: scale(1);
        transform: scale(1);
        -webkit-transform-origin: center center;
        transform-origin: center center;
        -webkit-animation-timing-function: ease-out;
        animation-timing-function: ease-out;
    }

    10% {
        -webkit-transform: scale(0.91);
        transform: scale(0.91);
        -webkit-animation-timing-function: ease-in;
        animation-timing-function: ease-in;
    }

    17% {
        -webkit-transform: scale(0.98);
        transform: scale(0.98);
        -webkit-animation-timing-function: ease-out;
        animation-timing-function: ease-out;
    }

    33% {
        -webkit-transform: scale(0.87);
        transform: scale(0.87);
        -webkit-animation-timing-function: ease-in;
        animation-timing-function: ease-in;
    }

    45% {
        -webkit-transform: scale(1);
        transform: scale(1);
        -webkit-animation-timing-function: ease-out;
        animation-timing-function: ease-out;
    }
}

@keyframes heartbeat {
    from {
        -webkit-transform: scale(1);
        transform: scale(1);
        -webkit-transform-origin: center center;
        transform-origin: center center;
        -webkit-animation-timing-function: ease-out;
        animation-timing-function: ease-out;
    }

    10% {
        -webkit-transform: scale(0.91);
        transform: scale(0.91);
        -webkit-animation-timing-function: ease-in;
        animation-timing-function: ease-in;
    }

    17% {
        -webkit-transform: scale(0.98);
        transform: scale(0.98);
        -webkit-animation-timing-function: ease-out;
        animation-timing-function: ease-out;
    }

    33% {
        -webkit-transform: scale(0.87);
        transform: scale(0.87);
        -webkit-animation-timing-function: ease-in;
        animation-timing-function: ease-in;
    }

    45% {
        -webkit-transform: scale(1);
        transform: scale(1);
        -webkit-animation-timing-function: ease-out;
        animation-timing-function: ease-out;
    }
}