#LanzoDemo {
    margin: 0;
    padding: 0;
    background: #010615;
    min-height: 100vh;
    box-sizing: border-box;
}

#LanzoDemo .lanzodemo-button {
    margin: 0;
    padding: 0;
    margin-top: 20px;
    box-sizing: border-box;
    position: relative;
    overflow: hidden;
    width: 100%;
    flex-wrap: wrap;
    background: #010615;
    text-align: center;
}

#LanzoDemo .allDemo {
    overflow: hidden;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    flex-wrap: wrap;
    background: #010615;
}

#LanzoDemo .allDemo .box {
    position: relative;
    width: 300px;
    height: 300px;
    display: flex;
    margin: 40px;
}

#LanzoDemo .allDemo .box .imgBx {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    transition: 0.5s ease-in-out;
    z-index: 2;
}

#LanzoDemo .allDemo .box .imgBx img {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
}

#LanzoDemo .allDemo .box:hover .imgBx {
    transform: translate(-35px, -35px);
}

#LanzoDemo .allDemo .box .content {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: white;
    z-index: 1;
    display: flex;
    justify-content: center;
    align-items: flex-end;
    padding: 15px;
    transition: 0.5s ease-in-out;
}

#LanzoDemo .allDemo .box:hover .content {
    transform: translate(35px, 35px);
}

#LanzoDemo .allDemo .box .content h2 {
    font-size: 20px;
    color: #111;
    font-weight: 500;
    line-height: 20px;
    letter-spacing: 1px;
    text-align: center;
}

#LanzoDemo .allDemo .box .content h2 span {
    font-size: 15px;
    font-weight: 400;
    letter-spacing: 2px;
}

#LanzoDemo .card {
    display: grid;
    grid-template-columns: 300px;
    grid-template-rows: 210px 210px 80px;
    grid-template-areas: "image""text""stats";

    /* border-radius: 18px; */
    border-radius: 0 30px 0 30px;
    background: white;
    box-shadow: 5px 5px 15px rgba(0, 0, 0, 0.9);
    font-family: roboto;
    text-align: center;

    transition: 0.5s ease;
    cursor: pointer;
    margin: 30px;
}


#LanzoDemo .card:hover {
    transform: scale(1.15);
    box-shadow: 5px 5px 15px rgba(0, 0, 0, 0.6);
}

@media screen and (max-width: 991px) {
    #LanzoDemo {
        height: auto;
    }

    #LanzoDemo .lanzodemo-button {
        height: 100px;
    }
}

@media screen and (max-width: 768px) {
    #LanzoDemo .allDemo .box:hover .content {
        transform: translate(0, 35px);
    }

    #LanzoDemo .allDemo .box:hover .imgBx {
        transform: translate(0, -35px);
    }
}