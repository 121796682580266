.amchart-container .amchart .chart-title {
    font-size: 18px;
    height: 2rem;
}

.amchart-container #chart1, .amchart-container #chart2, .amchart-container #chart3, .amchart-container #chart4 {
    height: 70vh;
}

@media screen and (max-width: 1024px) {
    .amchart-container .amchart .chart-title {
        font-size: 18px;
        height: 2rem;
    }

    .amchart-container #chart1, .amchart-container #chart2, .amchart-container #chart3, .amchart-container #chart4 {
        height: 60vh;
    }
}

@media screen and (max-width: 768px) {
    .amchart-container .amchart .chart-title {
        font-size: 16px;
    }

    .amchart-container #chart1, .amchart-container #chart2, .amchart-container #chart3, .amchart-container #chart4 {
        height: 50vh;
    }
}

@media screen and (max-width: 480px) {
    .amchart-container .amchart .chart-title {
        font-size: 14px;
    }

    .amchart-container #chart1, .amchart-container #chart2, .amchart-container #chart3, .amchart-container #chart4 {
        height: 40vh;
    }
}