#ProjectTache.tabcontent {
    flex-direction: column;
}

#ProjectTache .project {
    display: flex;
    align-items: center;
    margin-top: 20px;
}

#ProjectTache .project i {
    font-size: 28px;
    margin-left: 2%;
}

#ProjectTache .previous {
    position: absolute;
    left: 26%;
}

#ProjectTache .next {
    position: absolute;
    right: 23%;
}

#ProjectTache .menu {
    width: 40%;
    margin: 11px auto;
    margin-left: 29%;
}

#ProjectTache .icon {
    margin-right: 27%;
    width: 36px;
}

#ProjectTache .icon .plus {
    display: flex;
    font-size: 29px;
    height: 32px;
    padding: 17px;
    padding-top: 10px;
    color: #fff;
    background-color: #EB0909;
    border-radius: 8px;
    justify-content: center;
    align-items: center;
}

#ProjectTache .text {
    display: flex;
    font-size: 21px;
    height: 45px;
    padding: 18px;
    padding-top: 16px;
    color: #fff;
    background-color: #EB0909;
    border-radius: 8px;
    justify-content: center;
    align-items: center;
}

#ProjectTache .container {
    width: 84%;
    padding: 46% 0px 40px;
    border-radius: 10px;
}

#ProjectTache .entryArea {
    width: 75%;
    margin-top: -47%;
    margin-left: 22%;
    position: relative;
    line-height: 55px;
}

#ProjectTache .entryArea input {
    position: absolute;
    width: 74%;
    padding: 0px 30px;
    line-height: 52px;
    border-radius: 16px;
    border: 2px solid #E4DBE8;
    background: transparent;
    z-index: 1111;
}

#ProjectTache .entryArea input:focus,
input:valid {
    color: #000;
}

#ProjectTache .entryArea .labelline {
    position: absolute;
    font-size: 1.1em;
    color: #D6D5F0;
    padding: 0px 7px;
    margin: 1px 16px;
    background-color: #fff;
    transition: 0.2s ease;
}

#ProjectTache .entryArea input:focus+.labelline,
input:valid+.labelline {
    color: #A9A8BD;
    height: 30px;
    line-height: 30px;
    padding: 0 12px;
    transform: translate(-15px, -16px) scale(0.88);
    z-index: 1111;
}

#ProjectTache .entryArea button {
    position: relative;
    z-index: 2;
    width: 10%;
    margin-left: 64%;
    border-radius: 27%;
    background-color: #D0C8D4;
    color: #fff;
}

#ProjectTache .entryArea input:focus+.labelline+button,
#ProjectTache .entryArea input:valid+.labelline+button {
    display: block;
    z-index: 1111;
}

#ProjectTache .entryArea .labelline:focus-within+button {
    display: block;
    /* Affiche le bouton */
    background-color: #007bff;
    color: white;
    border: none;
    border-radius: 4px;
}

#ProjectTache .box {
    width: 97%;
    padding-top: 5%;
}

#ProjectTache .box ul li {
    display: flex;
    justify-content: space-between;
    align-items: center;
    list-style: none;
    padding: 15px;
    width: 100%;
    background: #fff;
    box-shadow: 0 5px 8px #9E97A1;
    transition: transform 0.5s;
}

#ProjectTache .box ul:hover li {
    opacity: 0.2;
}

#ProjectTache .box ul li:hover {
    transform: scale(1.1);
    z-index: 100;
    box-shadow: 0 5px 25px rgba(0, 0, 0, .2);
    color: #C90808;
    background: #FBF1FF;
    opacity: 1;
}

#ProjectTache .box ul li span {
    width: 50px;
    text-align: center;
    line-height: 20px;
    display: inline-block;
    font-size: 15px;
    transform: translateY(-2px);
}

#ProjectTache .box ul li:hover span {
    color: #C90808;
    font-size: 20px;
}

#ProjectTache .body {
    display: flex;
    height: 100vh;
    justify-content: center;
    align-items: center;
}

#ProjectTache .formDiv {
    max-width: 74%;
    height: 92%;
    background: #faf4f4;
    padding: 10px 30px;
    border-radius: 12px;
}

#ProjectTache .title {
    font-size: 22px;
    font-weight: 500;
    position: relative;
}

#ProjectTache .title::before {
    content: '';
    position: absolute;
    left: 0;
    bottom: 0;
    height: 3px;
    width: 84px;
    background: rgb(245 30 30);
}

#ProjectTache .projectDetails {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
}

#ProjectTache .projectDetails .input-box {
    margin: 20px 0 20px 0;
    width: calc(100% / 3 - 20px);
}

#ProjectTache .projectDetails .details {
    display: block;
    font-weight: 500;
    margin-bottom: 5px;
}

#ProjectTache .projectDetails input {
    height: 45px;
    width: 100%;
    outline: none;
    border-radius: 5px;
    border: 1px solid #ccc;
    padding-left: 15px;
    font-size: 16px;
    border-bottom-width: 3px;
    transition: all 0.3s ease;
}

#ProjectTache .projectDetails .input-box input:focus,
.projectDetails .input-box input:valid {
    border-color: rgb(230, 16, 16);
}

#ProjectTache .submit {
    display: flex;
    height: 45px;
    margin: 13px 0px;
    gap: 70px;
}

#ProjectTache .submit input {
    height: 100%;
    width: 100%;
    outline: none;
    color: #fff;
    border: none;
    font-size: 18px;
    font-weight: 500;
    border-radius: 5px;
    letter-spacing: 1px;
    background: rgb(245 30 30);
}

#ProjectTache .submit input:hover {
    background: rgb(199 19 19);
}

#ProjectTache .pagination {
    margin-top: 50px;
}