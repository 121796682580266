* {
	box-sizing: border-box;
	margin: 0;
	padding: 0;
	font-family: 'Fredoka', 'Sen', 'Roboto', 'Varela', 'Sora', 'Righteous', 'Orbitron', sans-serif;
	list-style-type: none;
	outline: none;
	border: none;
	text-decoration: none;
}

:root {
	--white: #FFFFFF;
	--red: #FF0000;
	--redGradient: radial-gradient(circle, rgba(255, 0, 0, 1) 0%, rgba(214, 22, 22, 1) 50%, rgba(159, 49, 49, 1) 100%);
	--redGradient2: radial-gradient(circle, rgba(255, 0, 0, 1) 0%, rgba(214, 22, 22, 1) 50%, rgba(205, 40, 40, 1) 100%);
}

#root {
	display: flex;
	flex-direction: column;
}

#Accueil, #Demo, #Offres, #Contact {
	display: flex;
	flex-direction: row;
	flex-wrap: wrap;
	margin-bottom: 3rem;
}

#Offres .container, #Demo .container, #Contact .container {
	margin: 0 auto;
	width: 80%;
	max-width: 1024px;
	min-width: 293px;
	font-family: "Fredoka";
}

.bg-redGradient {
	background: var(--redGradient);
}

/*Default indentation*/
.ident-text {
	text-indent: 5%;
}

/*Font Weight*/
.fw-400 {
	font-weight: 400 !important;
}

.fw-500 {
	font-weight: 500 !important;
}

.fw-600 {
	font-weight: 600 !important;
}

.fw-700 {
	font-weight: 700 !important;
}

/*Default click buton*/
.btn-click {
	font-size: 16px;
	text-decoration: none;
	color: white;
	background-color: black;
	padding: 0.5rem;
	border-radius: 50px;
	white-space: nowrap;
	cursor: pointer;
	transition: all 0.3s;
	padding-left: 15px;
}

.btn-click:hover {
	box-shadow: 0 5px 15px #000000;
	color: white;
}

.btn-click:hover img {
	transform: scale(1.1);
	transition: all 500ms;
}

.btn-click:active img {
	transform: scale(1.04);
}

.btn-click img.btn-smiley {
	max-width: 60px;
}

/*Snackbar Animation*/
#snackbar {
	visibility: hidden;
	min-width: 250px;
	margin-left: -125px;
	background-color: #333;
	color: #fff;
	text-align: center;
	border-radius: 2px;
	padding: 16px;
	position: fixed;
	z-index: 1;
	left: 50%;
	bottom: 30px;
}

#snackbar.show {
	visibility: visible;
	-webkit-animation: fadein 0.5s, fadeout 0.5s 2.5s;
	animation: fadein 0.5s, fadeout 0.5s 2.5s;
}

@-webkit-keyframes fadein {
	from {
		bottom: 0;
		opacity: 0;
	}

	to {
		bottom: 30px;
		opacity: 1;
	}
}

@keyframes fadein {
	from {
		bottom: 0;
		opacity: 0;
	}

	to {
		bottom: 30px;
		opacity: 1;
	}
}

@-webkit-keyframes fadeout {
	from {
		bottom: 30px;
		opacity: 1;
	}

	to {
		bottom: 0;
		opacity: 0;
	}
}

@keyframes fadeout {
	from {
		bottom: 30px;
		opacity: 1;
	}

	to {
		bottom: 0;
		opacity: 0;
	}
}


/*Screen General*/
@media only screen and (min-width: 0px) and (max-width: 480px) {
	* {
		font-size: 14px;
	}
}

@media only screen and (min-width: 481px) and (max-width: 768px) {
	* {
		font-size: 18px;
	}
}

@media only screen and (min-width: 769px) and (max-width: 1024px) {
	* {
		font-size: 21px;
	}
}