.tab-font {
    font-size: 18px;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
}

.tab-title {
    font-size: 27px;
    font-weight: 500;
}

.tab-links {
    z-index: 9;
    display: flex;
    flex-direction: column;
    background: var(--redGradient2);
    box-shadow: 0px 5px 5px rgb(0 0 0 / 50%);
    width: 20% !important;
    min-width: 130px;
    padding: 0;
    height: 100%;
    position: fixed;
    overflow-x: hidden;
    overflow-y: scroll;
    scrollbar-width: none;
}

.tab-links img.logo {
    width: 25%;
    max-width: 80px;
}

.tab-links button {
    background-color: inherit;
    cursor: pointer;
    padding: 14px 28px;
    transition: 0.3s;
    font-size: 22px;
    color: #FFF;
}

.tab-links button:hover {
    scale: 1.3;
}

.tab-links button.active {
    font-size: 23px;
    font-weight: 600;
}

.tabcontent {
    right: 0;
    display: flex;
    width: 80%;
    position: absolute;
}

.refSearch {
    background-position: 10px 12px;
    background-repeat: no-repeat;
    width: 100%;
    font-size: 16px;
    padding: 12px 20px 12px 40px;
    border: 1px solid #ddd;
    margin-bottom: 12px;
}

@media screen and (max-width: 960px) {

    .tab-font,
    .tab-font button {
        font-size: 18px;
    }

    .tab-links button {
        padding: 10px 18px;
        font-size: 18px;
    }

    .tab-links button.active {
        font-size: 18px;
    }
}

@media screen and (max-width: 550px) {

    .tab-font,
    .tab-font button {
        font-size: 15px;
    }

    .tab-links {
        width: min-content;
    }

    .tab-links button {
        padding: 8px 16px;
        font-size: 15px;
    }

    .tab-links button.active {
        font-size: 13px;
    }
}