#admin-login input[type=text], #admin-login input[type=password] {
    width: 100%;
    padding: 12px 20px;
    margin: 8px 0;
    display: inline-block;
    border: 1px solid #ccc;
    box-sizing: border-box;
}

#admin-login button {
    background: var(--redGradient2);
    font-weight: 500;
    color: white;
    padding: 14px 20px;
    margin: 8px 0;
    border: none;
    cursor: pointer;
    width: 100%;
}

#admin-login button:hover {
    opacity: 0.8;
}

#admin-login .cancelbtn {
    width: auto;
    padding: 10px 18px;
    background-color: #f44336;
}

#admin-login .imgcontainer {
    text-align: center;
    margin: 24px 0 12px 0;
}

#admin-login img.avatar {
    width: 150px;
    border-radius: 50%;
}

#admin-login .container {
    padding: 16px;
}

#admin-login span.psw {
    float: right;
    padding-top: 16px;
}

#admin-login img.avatar {
    width: 150px;
    border-radius: 50%;
}

@media screen and (max-width: 768px) {
    #admin-login img.avatar {
        width: 90px;
        border-radius: 50%;
    }
}

@media screen and (max-width: 480px) {
    #admin-login img.avatar {
        width: 60px;
        border-radius: 50%;
    }
}