.mynavbar {
    display: flex;
    background: var(--redGradient);
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    font-size: 1.2rem;
    position: sticky;
    top: 0;
    height: 0;
    z-index: 999;
    box-shadow: 0px 5px 5px rgba(0, 0, 0, 0.5);
    transition: all 1s;
}

.mynavbar.active {
    height: 80px;
    transition: all 1s;
}

.mynavbar .menu-icon {
    display: none;
}

.mynavbar .mynavbar-menu {
    display: none;
    list-style: none;
    text-align: center;
    width: 80vw;
    justify-content: space-around;
    margin: 0;
    padding: 0;
}

.mynavbar.active .mynavbar-menu {
    display: flex;
    transition: all 1s;
}

.mynavbar .mynavbar-item {
    height: 80px;
    font-size: 22px;
}

.mynavbar .mynavbar-item-active {
    font-weight: 700;
    font-size: 23px;
}

.mynavbar .mynavbar-item:hover, .mynavbar .mynavbar-item-active:hover {
    padding: 0 20px;
    transition: all 0.5s;
}

.mynavbar .mynavbar-links {
    display: flex;
    align-items: center;
    text-decoration: none;
    padding: 0.5rem 1rem;
    height: 100%;
    color: #FFF;
}

.mynavbar .mynavbar-links:hover {
    color: #FFF;
    font-weight: 500;
    transition: 1.5s;
    transform: scale(1.2);
}

.mynavbar .mynavbar-links-mobile {
    display: none;
}

@media screen and (max-width: 960px) {

    .mynavbar {
        height: 60px;
    }

    .mynavbar.active {
        height: 60px;
    }

    .mynavbar .menu-icon {
        display: block;
        position: absolute;
        top: 0;
        left: 51%;
        transform: translate(-100%, 0%);
        font-size: 1.8rem;
        cursor: pointer;
    }

    .mynavbar .menu-icon.active {
        transform: translate(-100%, 10%);
    }

    .mynavbar .menu-icon .fa-times {
        font-size: 2rem;
    }

    .mynavbar .mynavbar-menu {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        top: 60px;
        width: 100%;
        height: 0px;
        position: absolute;
        margin: 0;
        opacity: 1;
        overflow: hidden;
        transition: all 0.1s linear;
    }

    .mynavbar .mynavbar-menu.active {
        height: 240px;
        background: var(--white);
        box-shadow: 0px 5px 5px rgb(0 0 0 / 50%);
        transition: all 0.3s linear;
    }

    .mynavbar .mynavbar-item, .mynavbar .mynavbar-item-active {
        height: 60px;
    }

    .mynavbar .mynavbar-item:hover, .mynavbar-item-active:hover {
        border: none;
    }

    .mynavbar .mynavbar-links {
        color: black;
        text-align: center;
        width: 100%;
        display: grid;
    }

    .mynavbar .mynavbar-links:hover {
        color: #242424;
        border-radius: 0;
    }
}

@media screen and (max-width: 492px) {
    .menu-icon {
        transform: translate(-100%, 30%) !important;
    }

    .menu-icon.active {
        transform: translate(-100%, 40%) !important;
    }
}