.design1 {
    background: linear-gradient(90deg, rgba(0, 206, 255, 1) 0%, rgba(255, 0, 0, 1) 50%, rgba(16, 0, 255, 1) 100%);
    display: table;
    width: 100vw;
    height: 100vh;
    max-height: 50vw;
    padding-top: 9%;
}

.design1 .text {
    color: transparent;
    -webkit-text-stroke: 1px #fff;
    font-size: 15vw;
    font-weight: 600;
    text-align: center;
    background: url('./../img/back.png');
    -webkit-background-clip: text;
    background-size: cover;
    background-position: 0 0;
    animation: back 20s linear infinite;
}

.design1 .slogan {
    color: white;
    font-size: 4vw;
    text-align: center;
}

@keyframes back {
    100% {
        background-position: 2000px 0;
    }
}