.amchart-container, .galery-container, .todo-container {
    height: auto;
    background: #19231f;
    color: #adbdb3;
    padding: 1rem 10%;
    text-align: center;
}

.demo-name {
    font-size: 30px;
    font-weight: 600;
    text-align: center;
}

@media screen and (max-width: 1024px) {
    .demo-name {
        font-size: 25px;
    }
}

@media screen and (max-width: 768px) {
    .demo-name {
        font-size: 22px;
    }
}

@media screen and (max-width: 480px) {
    .demo-name {
        font-size: 20px;
    }
}