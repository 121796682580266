.blockEvent{
    position:absolute !important;
    background-color: #007bff !important;
    border: 1px solid black !important;
    border-radius: 1% !important;
    color:white;
    /* height: 60px !important;
    width : 1000px !important; */
}
.loadingDay{
    margin-left: 50%;
    margin-top: 25%;
    display: block;
}

.react-draggable, .cursor {
    cursor: move;
}
.no-cursor {
    cursor: auto;
}
.cursor-y {
    cursor: ns-resize;
}
.cursor-x {
    cursor: ew-resize;
}
.cursorDefault{
    cursor: default !important;
}